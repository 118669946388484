import slugify from 'slugify';

export function useAnchorTitle(title) {
    try {
        return slugify(title, { lower: true, trim: true, strict: true });
    } catch (err) {
        console.log(err);
        return '';
    }
}
